import {
    AssessmentDescriptor,
    AssessmentModule,
    AssessmentPrompt,
    ResponseType,
} from '@amzn/aws-assessment-template-management-service-typescript-client';
import { AssessmentResponseTypes, MetadataValueType } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { AssigneeAnswer, QuestionAnswer } from '@amzn/awscat-data-collection-service-typescript-client';
import { FlashContextInterface, withFlashContext } from '@amzn/awscat-react-components';
import { Alert, Box, TextContent } from '@amzn/awsui-components-react';
import { useMutation, useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AssessmentPromptHelpPanelBody from './AssessmentPromptHelpPanel';
import AssessmentTimelineNav from './AssessmentTimelineNav';
import { Comment } from './CommentBox';
import AssessmentPromptDate from './date/AssessmentPromptDate';
import AssessmentPromptNumber from './number/AssessmentPromptNumber';
import AssessmentPromptRating, { ParticipantRatingResponse, ParticipantRatingResponseMap } from './rating/AssessmentPromptRating';
import AssessmentPromptResponse from './response/AssessmentPromptResponse';
import AssessmentPromptMultiSelect from './select/AssessmentPromptMultiSelect';
import AssessmentPromptSingleSelect from './select/AssessmentPromptSingleSelect';
import AssessmentPromptYesNo from './yesno/AssessmentPromptYesNo';
import { EditableDescriptorKey } from '../../../../api/templateManagement/TemplateManagementClient';
import {
    GET_RESPONSE_ANALYSIS,
    POST_SHARED_DOC_TO_SESSION,
    generateWbsSolicitId,
    getAnalysisDocsForPrompts,
    getAnalysisResultDistribution,
    isWbsSessionActive,
} from '../../../../api/wbs/WbsClient';
import { generateSolicit } from '../../../../api/wbs/WbsSolicitGenerator';
import { AnalysisDoc, PromptIdToAnalysisDocMap, ResponseAnalysisResult, defaultAnalysisDoc } from '../../../../api/wbs/WbsTypes';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../common/AppLabelsContext';
import { transformWbsAnalysisDocToComments } from '../../../../common/Utils';
import rumClient from '../../../../common/monitoring/RumClient';
import { CurrentTemplateState, getModuleFromModuleId } from '../../../administration/manage-templates/edit-template/CurrentTemplateSlice';
import { TemplatePromptViewModel } from '../../../administration/manage-templates/edit-template/TemplateModels';
import {
    getCurrentPromptText,
    getDescriptorForLocale,
    getEditableDescriptor,
} from '../../../administration/manage-templates/edit-template/TemplateUtils';
import TemplateEditableText from '../../../common/TemplateEditableText';
import { clearAppHelpPanel, updateAppHelpPanel } from '../../../common/help-panel/AppHelpPanelSlice';
import { withLocalizationContext } from '../../../localization/LocalizationContext';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { AssessmentPromptViewModel } from '../../AssessmentPromptViewModel';
import { generateAssessmentPromptUrl, promptToPromptText } from '../../Utils';
import { EventResponseFilter, setLiveSessionResponses, updateAnalysisDocForPrompt, updateEventResponseFilter } from '../CurrentAssessmentSlice';

export const assigneeAnswerToComment = (assigneeAnswer: AssigneeAnswer): Comment => {
    return {
        content: assigneeAnswer.comment,
        author: assigneeAnswer.userEmail,
        date: DateTime.fromISO(assigneeAnswer.lastSubmittedDate).toLocaleString(DateTime.DATE_MED),
        eventType: 'pre-event',
    };
};

const createAssigneeAnswerMap = (assigneeAnswers: AssigneeAnswer[]): Map<string, Array<Comment>> => {
    const answerMap: Map<string, Array<Comment>> = new Map<string, Array<Comment>>();
    (assigneeAnswers || []).forEach((assigneeAnswer: AssigneeAnswer) => {
        const existingMapAnswers: Array<Comment> = answerMap.get(assigneeAnswer.value) || [];
        existingMapAnswers.push(assigneeAnswerToComment(assigneeAnswer));
        answerMap.set(assigneeAnswer.value, existingMapAnswers);
    });
    return answerMap;
};

interface LiveSessionState {
    sessionId: string;
    sessionIsActive: boolean;
    responseAnalysisDoc: AnalysisDoc;
    sharedDoc: string;
}

type AssessmentDetailsBodyProp = AppLabelsContextInterface &
    FlashContextInterface & {
        currentAssessmentId: string | undefined;
        preEventAnswers: Map<string, QuestionAnswer> | undefined;
        changeUrl: boolean;
        updateHelpPanel: boolean;
        shouldDisplayTemplate: boolean;
    };

const AssessmentDetailsBody: FunctionComponent<AssessmentDetailsBodyProp> = ({
    flash,
    appLabels,
    currentAssessmentId,
    preEventAnswers,
    changeUrl = true,
    updateHelpPanel = true,
    shouldDisplayTemplate = false,
}): JSX.Element | null => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const currentAssessment = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const selectedSnapshotId = useAppSelector((state) => state.currentAssessmentState.selectedSnapshotId);
    const promptConfig = currentAssessment?.template?.defaults?.questionnaireAnswers;
    const hideComment = promptConfig?.hideComment;

    const currentPrompt = useAppSelector((state) => state.currentAssessmentState.currentPromptState.currentPrompt);
    const allPrompts: AssessmentPromptViewModel[] = useAppSelector((state) => state.currentAssessmentState.currentPromptState.prompts);
    const promptId = currentPrompt?.id;
    const errorMessage = useAppSelector((state) => state.currentAssessmentState.errorMessage);

    // Template state
    const currentTemplateState: CurrentTemplateState = useAppSelector((state) => state.currentTemplateState);
    const currentTemplatePrompt: TemplatePromptViewModel | undefined = useAppSelector(
        (state) => state.currentTemplateState.currentPromptState.currentPrompt
    );
    const currentTemplatePromptIndex: number = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentPromptIndex);
    const currentTemplateModuleId: string = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentModuleId);
    const templateLocale: string = useAppSelector((state) => state.currentTemplateState.templateLocale);
    // The metadata below is defined for all prompts in a template
    const templatePromptMetadata: AssessmentPrompt[] | undefined = useAppSelector(
        (state) => state.currentTemplateState.currentTemplate?.config?.promptMetadataConfig?.metadataPrompts
    );

    // Live session related state
    const liveSessionEnabled = selectedSnapshotId ? false : promptConfig?.wbsEnabled;
    const currentLiveSessionInfo = useAppSelector((state) => state.currentAssessmentState.currentLiveSessionInfo);
    const selectedLiveSessionId = useAppSelector((state) => state.currentAssessmentState.selectedLiveSessionId);
    const selectedLiveSessionIsActive = currentLiveSessionInfo ? isWbsSessionActive(currentLiveSessionInfo) : null;

    const eventResponseFilter = useAppSelector((state) => state.currentAssessmentState.currentPromptState.eventResponseFilter);

    const [liveSessionState, setLiveSessionState] = useState<LiveSessionState>({
        sessionId: selectedLiveSessionId,
        sessionIsActive: selectedLiveSessionIsActive,
        responseAnalysisDoc: defaultAnalysisDoc,
        sharedDoc: JSON.stringify(generateSolicit(null, promptConfig)),
    });

    const [postSharedDocToSession, postSharedDocToSessionApiResult] = useMutation(POST_SHARED_DOC_TO_SESSION, {
        onCompleted: (data) => {
            const sharedDoc = data.postSharedDocToSession.sharedDoc;
            setLiveSessionState({ ...liveSessionState, sharedDoc });
        },
        onError: (error) => {
            // Unable to post question to live session.
            // Simply log error and proceed to minimize live session interruptions.
            rumClient.recordError(
                `postSharedDocToSession() Error: selectedLiveSessionId=${selectedLiveSessionId} currentPrompt?.id=${currentPrompt?.id}`
            );
            rumClient.recordError(error);
        },
    });

    // Load initial live session state whenever session ID changes
    useEffect(() => {
        const loadInitialLiveSessionState = async () => {
            const liveSessionPromptIds: string[] = allPrompts.map((prompt) => prompt.id);

            const responseDocsForAllPrompts: PromptIdToAnalysisDocMap = await getAnalysisDocsForPrompts(selectedLiveSessionId, liveSessionPromptIds);
            dispatch(setLiveSessionResponses(responseDocsForAllPrompts));
        };

        if (liveSessionEnabled && selectedLiveSessionId) {
            loadInitialLiveSessionState();
        }
        // Reload whenever session ID changes (rather than whenever prompts change)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [liveSessionEnabled, selectedLiveSessionId, dispatch]);

    // Execute periodic response analysis query for the selected live session
    useQuery(GET_RESPONSE_ANALYSIS, {
        skip: !liveSessionEnabled || !selectedLiveSessionId || !currentPrompt?.id,
        pollInterval: selectedLiveSessionIsActive ? 10000 : 0, // 10 sec polling for active session
        variables: {
            input: {
                sessionId: selectedLiveSessionId,
                solicitId: generateWbsSolicitId(currentPrompt?.id || ''),
            },
        },
        notifyOnNetworkStatusChange: true, // need this to be true to trigger onComplete for polling
        onCompleted: (data) => {
            const responseAnalysisResult: ResponseAnalysisResult = data.getResponseAnalysis;
            try {
                const responseAnalysisDoc: AnalysisDoc = JSON.parse(responseAnalysisResult.analysisDoc);
                setLiveSessionState({ ...liveSessionState, responseAnalysisDoc });

                // Update response doc in Redux state
                if (responseAnalysisDoc) {
                    dispatch(updateAnalysisDocForPrompt({ promptId: currentPrompt?.id, analysisDoc: responseAnalysisDoc }));
                }
            } catch (err) {
                // analysisDoc is malformed
                // Simply log error and proceed to minimize live session interruptions.
                rumClient.recordError(err);
            }
        },
        onError: (error) => {
            // Unable to retrieve live session results.
            // Simply log error and proceed to minimize live session interruptions.
            rumClient.recordError(`selectedLiveSessionId=${selectedLiveSessionId} currentPrompt?.id=${currentPrompt?.id}, error=${error}`);
        },
    });

    // Pre-event/Live event participant response collection
    const preEventAnswer = preEventAnswers ? preEventAnswers.get(promptId) : null;
    const preEventAnswerMap = createAssigneeAnswerMap(preEventAnswer?.assigneeAnswers);
    let participantComments: Comment[] = [];
    const participantRatingResponseMap: ParticipantRatingResponseMap = new Map<string, ParticipantRatingResponse>();
    if (eventResponseFilter === EventResponseFilter.liveEvent) {
        participantComments = transformWbsAnalysisDocToComments(appLabels.assessment.facilitate.live_event, liveSessionState.responseAnalysisDoc);
        // WBS rating analysis results
        const ratingDistributionResult = getAnalysisResultDistribution(liveSessionState.responseAnalysisDoc);
        ratingDistributionResult &&
            Object.keys(ratingDistributionResult).forEach((rating) => {
                const votes = ratingDistributionResult[rating] ?? 0;
                participantRatingResponseMap.set(rating, {
                    rating,
                    votes,
                    participantComments: [], // ToDo: support comments linked to rating: https://i.amazon.com/issues/A2T-1689
                });
            });
    } else if (eventResponseFilter === EventResponseFilter.preEvent) {
        for (const key of preEventAnswerMap.keys()) {
            switch (currentPrompt.responseType) {
                case AssessmentResponseTypes.NUMBER:
                case AssessmentResponseTypes.TEXT: {
                    const authorInfo = preEventAnswerMap.get(key);
                    const comment = { ...authorInfo[0], content: key };
                    participantComments.push(comment);
                    break;
                }
                case AssessmentResponseTypes.RATING:
                case AssessmentResponseTypes.YES_NO: {
                    const rating = key;
                    const preEventComments = preEventAnswerMap.get(rating);
                    participantRatingResponseMap.set(rating, {
                        rating,
                        votes: preEventComments.length,
                        participantComments: preEventComments,
                    });
                    break;
                }
                default: {
                    rumClient.recordError(`AssessmentDetailsBody: - unexpected responseType=${currentPrompt.responseType}`);
                }
            }
        }
    } else {
        // eventResponseFilter not initialized
        if (selectedLiveSessionId) {
            // initialize to filter live event if there's a session created
            dispatch(updateEventResponseFilter(EventResponseFilter.liveEvent));
        } else if (preEventAnswers) {
            // if no live session selected and pre-event response is available, default filter to preEvent
            dispatch(updateEventResponseFilter(EventResponseFilter.preEvent));
        }
    }

    const buildMetadataCollectionPrompt = useCallback(() => {
        // For now, only support one singleSelection metadata collection
        if (shouldDisplayTemplate) {
            const templateMetadataToCollect = templatePromptMetadata?.at(0);
            switch (templateMetadataToCollect?.responseType) {
                case ResponseType.SingleSelection:
                    return (
                        <AssessmentPromptSingleSelect
                            templateMetadataToCollect={templateMetadataToCollect}
                            shouldDisplayTemplate={shouldDisplayTemplate}
                        />
                    );
                default:
                    return null;
            }
        } else {
            const responseMetadataConfig = promptConfig?.metadataConfig;
            const metadataToCollect = responseMetadataConfig?.customMetadata[0];
            switch (metadataToCollect?.valueType) {
                case MetadataValueType.singleSelection:
                    return <AssessmentPromptSingleSelect metadataToCollect={metadataToCollect} />;
                default:
                    return null;
            }
        }
    }, [promptConfig?.metadataConfig, templatePromptMetadata, shouldDisplayTemplate]);

    const buildSectionText = useCallback(() => {
        const promptParentModule: AssessmentModule = getModuleFromModuleId(currentTemplateModuleId);
        const promptGrandparentModule: AssessmentModule = getModuleFromModuleId(promptParentModule?.parentModule?.moduleId);
        const descriptorToShow: AssessmentDescriptor | undefined =
            shouldDisplayTemplate &&
            (promptGrandparentModule
                ? getEditableDescriptor(currentTemplateState, promptGrandparentModule.descriptors[0].descriptorId)
                : getEditableDescriptor(currentTemplateState, promptParentModule?.descriptors?.[0]?.descriptorId));

        return (
            <Box className='awscat-assessment-details-section-title'>
                <TemplateEditableText descriptor={descriptorToShow} keyToEdit={EditableDescriptorKey.Name} numRows={1}>
                    <h1>{shouldDisplayTemplate ? descriptorToShow?.name : currentPrompt?.sectionName}</h1>
                </TemplateEditableText>
            </Box>
        );
    }, [currentPrompt, currentTemplateModuleId, currentTemplateState, shouldDisplayTemplate]);

    /**
     * Builds appropriate Prompt component based on response type
     * @returns specific react prompt component
     */
    const buildPrompt = () => {
        if (shouldDisplayTemplate && !currentTemplatePrompt) {
            return <div className='awscat-assessment-details-prompt'>{appLabels.assessment.facilitate.category_has_no_questions}</div>;
        }

        const promptHeading = (
            <p className='awscat-assessment-details-heading' data-testid='assessment-prompt-heading'>
                <TemplateEditableText
                    descriptor={getDescriptorForLocale(currentTemplatePrompt?.descriptors, templateLocale)}
                    keyToEdit={EditableDescriptorKey.Description}
                >
                    {shouldDisplayTemplate ? getCurrentPromptText(currentTemplateState) : promptToPromptText(currentPrompt, promptConfig)}
                </TemplateEditableText>
            </p>
        );

        // If text response, just show question and text response box
        if (currentPrompt?.responseType === AssessmentResponseTypes.TEXT || currentTemplatePrompt?.responseType === ResponseType.Text) {
            return (
                <div className='awscat-assessment-details-prompt' data-testid='assessment-prompt'>
                    <div className='awscat-assessment-details-prompt-single-column' data-testid='assessment-prompt-single-column'>
                        {promptHeading}
                        <AssessmentPromptResponse participantComments={participantComments} />
                    </div>
                </div>
            );
        }

        // For all other response types, prompt is split into two columns:
        // Left side has the prompt heading and a text box for observations, unless the response type is TEXT
        // Right side contains the response area (selection, number, date, text, etc.)
        return (
            <div className='awscat-assessment-details-prompt' data-testid='assessment-prompt'>
                <Box className='awscat-assessment-details-prompt-left-column' data-testid='assessment-prompt-left-column'>
                    <div>
                        {promptHeading}
                        {hideComment ? null : (
                            <AssessmentPromptResponse participantComments={participantComments} shouldDisplayTemplate={shouldDisplayTemplate} />
                        )}
                    </div>
                    {buildMetadataCollectionPrompt()}
                </Box>
                <Box className='awscat-assessment-details-prompt-right-column' data-testid='assessment-prompt-right-column'>
                    <AssessmentPromptRating
                        participantRatingResponseMap={participantRatingResponseMap}
                        className='item-1'
                        shouldDisplayTemplate={shouldDisplayTemplate}
                    />
                    <AssessmentPromptYesNo
                        participantRatingResponseMap={participantRatingResponseMap}
                        className='item-2'
                        shouldDisplayTemplate={shouldDisplayTemplate}
                    />
                    <AssessmentPromptNumber participantComments={participantComments} shouldDisplayTemplate={shouldDisplayTemplate} />
                    <AssessmentPromptSingleSelect participantComments={participantComments} shouldDisplayTemplate={shouldDisplayTemplate} />
                    <AssessmentPromptMultiSelect participantComments={participantComments} shouldDisplayTemplate={shouldDisplayTemplate} />
                    <AssessmentPromptDate participantComments={participantComments} shouldDisplayTemplate={shouldDisplayTemplate} />
                </Box>
            </div>
        );
    };

    // useEffect for updating help panel
    useEffect(() => {
        const cleanup = () => {
            if (updateHelpPanel) {
                // Component unmounted, restore help panel to default
                dispatch(clearAppHelpPanel());
            }
        };

        if (updateHelpPanel) {
            let header: string;
            let categoryDescriptor: AssessmentDescriptor | undefined;
            let categoryContext: string;
            let promptIndex: number;
            let promptDescriptor: AssessmentDescriptor | undefined;
            let promptContext: string;
            if (shouldDisplayTemplate) {
                const promptParentModule = getModuleFromModuleId(currentTemplateModuleId);
                categoryDescriptor = getEditableDescriptor(currentTemplateState, promptParentModule.descriptors?.at(0)?.descriptorId);
                header = categoryDescriptor?.name || '';
                categoryContext = categoryDescriptor?.helpContext || '';
                promptIndex = currentTemplatePromptIndex + 1; // convert 0 index to 1 index
                promptDescriptor = getEditableDescriptor(currentTemplateState, currentTemplatePrompt?.descriptors?.at(0)?.descriptorId);
                promptContext = promptDescriptor?.helpContext || '';
            } else {
                header = currentPrompt?.categoryName;
                categoryContext = currentPrompt?.categoryContext?.replace('{text=', '').replace('}', '') || '';
                promptIndex = currentPrompt?.index;
                promptContext = currentPrompt?.context?.text || '';
            }
            dispatch(
                updateAppHelpPanel({
                    header: (
                        <TextContent id='header'>
                            <h2>{header}</h2>
                        </TextContent>
                    ),
                    content: (
                        <AssessmentPromptHelpPanelBody
                            categoryContext={categoryContext}
                            categoryDescriptor={categoryDescriptor}
                            promptIndex={promptIndex}
                            promptContext={promptContext}
                            promptDescriptor={promptDescriptor}
                            shouldDisplayTemplate={shouldDisplayTemplate}
                        />
                    ),
                })
            );
        }

        return cleanup;
    }, [
        currentPrompt,
        currentTemplatePrompt,
        currentTemplatePromptIndex,
        currentTemplateModuleId,
        dispatch,
        shouldDisplayTemplate,
        templateLocale,
        updateHelpPanel,
        currentTemplateState,
    ]);

    // useEffect for updating prompt URL and live session
    useEffect(() => {
        if (currentPrompt && currentAssessmentId && currentAssessmentId === currentPrompt?.assessmentId) {
            if (changeUrl) {
                // Keeps browser URL in sync with changing prompts
                const assessmentId = currentPrompt?.assessmentId;
                const snapshotId = currentPrompt?.snapshotId;
                const promptId = currentPrompt?.id;
                const newUrl = generateAssessmentPromptUrl(assessmentId, snapshotId, promptId);
                history.push(newUrl);
            }

            // push prompt to WBS
            if (liveSessionEnabled) {
                if (liveSessionState.sessionId !== selectedLiveSessionId) {
                    // selected session has changed.
                    setLiveSessionState({
                        sessionId: selectedLiveSessionId,
                        sessionIsActive: selectedLiveSessionIsActive,
                        responseAnalysisDoc: defaultAnalysisDoc,
                        sharedDoc: JSON.stringify(generateSolicit(null, promptConfig)),
                    });
                } else {
                    if (selectedLiveSessionId && selectedLiveSessionIsActive) {
                        // solicit current prompt if session is active
                        const wbsSolicit = generateSolicit(currentPrompt, promptConfig);
                        const wbsSolicitDoc = JSON.stringify(wbsSolicit);
                        if (!postSharedDocToSessionApiResult.loading) {
                            if (wbsSolicitDoc !== liveSessionState.sharedDoc) {
                                // session is acprompt (e.g. sharedDoc) has changed
                                postSharedDocToSession({
                                    variables: {
                                        input: {
                                            sessionId: selectedLiveSessionId,
                                            sharedDoc: wbsSolicitDoc,
                                        },
                                    },
                                });
                            }
                        }
                    }
                }
            }
        }
    }, [
        history,
        currentAssessmentId,
        flash,
        appLabels.assessment.live_session.error_api,
        currentPrompt,
        dispatch,
        liveSessionEnabled,
        postSharedDocToSession,
        selectedLiveSessionId,
        selectedLiveSessionIsActive,
        postSharedDocToSessionApiResult.loading,
        promptConfig,
        liveSessionState.sharedDoc,
        liveSessionState.sessionId,
        selectedSnapshotId,
        changeUrl,
        updateHelpPanel,
    ]);

    // If we're in assessment mode and there's no assessment, don't display anything
    if (!shouldDisplayTemplate && !currentAssessmentId) {
        return null;
    }

    if (errorMessage) {
        return (
            <Alert
                visible={true}
                dismissAriaLabel={appLabels.user_actions.close_alert}
                type='error'
                header={appLabels.assessment.facilitate.error_loading_question}
            >
                {errorMessage}
            </Alert>
        );
    } else {
        return (
            <div data-testid='assessment-body'>
                {buildSectionText()}
                <AssessmentTimelineNav shouldDisplayTemplate={shouldDisplayTemplate} />
                {buildPrompt()}
            </div>
        );
    }
};

export default withLocalizationContext(withFlashContext(withAppLabelsContext(AssessmentDetailsBody)));
