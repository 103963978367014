import {
    AssessmentPrompt,
    AssessmentTemplateMetadata,
    AssessmentTemplate as TmAssessmentTemplate,
} from '@amzn/aws-assessment-template-management-service-typescript-client';
import {
    OptionalAssessmentMetadataKey as A2sOptionalAssessmentMetadataKey,
    AssessmentResponse,
    AssessmentResponseBoolean,
    AssessmentResponseDate,
    AssessmentResponseMultiSelection,
    AssessmentResponseNumber,
    AssessmentResponseRating,
    AssessmentResponseSingleSelection,
    AssessmentResponseText,
    AssessmentResponseTypes,
    AssessmentSection,
    AssessmentSnapshot,
    AssessmentStatus,
    AssessmentTemplate,
    AssessmentTemplateDefaultsAnswers,
} from '@amzn/awscat-aws-assessment-service-typescript-client';
import { ValidationError, validate } from 'class-validator';

import { AssessmentPromptViewModel, PromptId } from './AssessmentPromptViewModel';
import { AssessmentViewModel } from './AssessmentViewModel';
import { AssessmentCreateState } from './create/AssessmentCreateState';
import { getFirstPromptIdInPromptCategory } from './facilitate/CurrentAssessmentSlice';
import { getAssessmentMetadataProvider } from './results/model/Assessment';
import templateManagementClient from '../../api/templateManagement/TemplateManagementClient';
import { LIST_TEMPLATES_QUERY } from '../../api/templateManagement/TemplateManagementQueries';
import { AppLabels } from '../../common/AppLabels';
import Constants from '../../common/Constants';
import { getResponseYesNoLabelLocalized, notEmpty } from '../../common/Utils';
import { AssessmentPermissionManager } from '../../common/auth/AssessmentPermissionManager';
import rumClient from '../../common/monitoring/RumClient';
import { getScoreLabel } from '../../common/score/ScoreUtils';
import { Assessment } from '../../models/Assessment';

interface ResultError {
    message: string;
    path: Array<string | number>;
}

/**
 * Retrieves all the templates available to the user. Use this when listing the templates from which
 * a user can create an assessment
 * @returns all the templates that the user has access to, along with any errors that occurred during retrieval
 */
export const getAssessmentTemplates = async (): Promise<{ errors: ResultError[]; templates: AssessmentTemplateMetadata[] }> => {
    let templates: AssessmentTemplateMetadata[] = [];
    let errors: ResultError[] = [];
    try {
        const response = await templateManagementClient.query({
            query: LIST_TEMPLATES_QUERY,
        });
        if (response?.data?.listTemplates) {
            const responseItems: AssessmentTemplateMetadata[] = response.data.listTemplates;
            templates = templates.concat(responseItems.filter(notEmpty));
        }
    } catch (error: any) {
        rumClient.recordError(error);
        if (error.errors) {
            errors = errors.concat(error.errors);
        }
        if (error.data) {
            const responseItems: AssessmentTemplateMetadata[] = error.data.listTemplates;
            templates = templates.concat(responseItems.filter(notEmpty));
        }
    }

    return { templates, errors };
};

export const consideredResponded = (responseType: AssessmentResponseTypes | null, response: AssessmentResponse | null): boolean => {
    const responded = false;
    if (response && responseType) {
        switch (responseType) {
            case AssessmentResponseTypes.NUMBER:
                return (response as AssessmentResponseNumber).intValue !== null;
            case AssessmentResponseTypes.RATING:
                return (response as AssessmentResponseRating).intValue !== null;
            case AssessmentResponseTypes.YES_NO:
                return (response as AssessmentResponseBoolean).booleanValue !== null;
            case AssessmentResponseTypes.TEXT:
                return !!(response as AssessmentResponseText).stringValue;
            case AssessmentResponseTypes.SINGLE_SELECTION:
                return (response as AssessmentResponseSingleSelection).singleSelectValue !== null;
            case AssessmentResponseTypes.MULTI_SELECTION:
                return (response as AssessmentResponseMultiSelection).multiSelectValues?.length > 0;
            case AssessmentResponseTypes.DATE_VALUE:
                return (response as AssessmentResponseDate).dateValue !== null;
            default:
                rumClient.recordError(`consideredResponded() - unrecognized response type ${responseType}`);
        }
    }
    return responded;
};

export const isPromptConsideredOptional = (promptId: string): boolean => {
    return promptId.toLowerCase().startsWith('optional');
};

/**
 * Returns whether a section is considered optional
 * @param section
 * @returns true if a section is optional, false otherwise
 */
export const isSectionConsideredOptional = (section: AssessmentSection): boolean => {
    return !!section?.showIfAnswerYes; // section is shown only if another question is answered with yes
};

/**
 * Returns whether a section is shown to the user
 * @param section
 * @param assessment
 * @returns true if section is shown to the user, false otherwise
 */
export const isSectionShown = (section: AssessmentSection, assessment: AssessmentViewModel): boolean => {
    if (!section) return false;

    if (!isSectionConsideredOptional(section)) return true; // non-optional sections are always shown

    // Optional sections are shown if their corresponding prompt is answered with yes
    const dependentPrompt = findPrompt(assessment, section?.showIfAnswerYes);
    if (!dependentPrompt) return false;

    const dependentPromptResponse = dependentPrompt.response as AssessmentResponseBoolean;
    return dependentPromptResponse?.booleanValue === true;
};

/**
 * Checks if a section has a provided response type
 * @param section
 * @param responseType
 * @returns whether at least one prompt in the section is of the provided response type
 */
export const doesSectionHaveResponseType = (section: AssessmentSection, responseType: AssessmentResponseTypes): boolean => {
    let hasResponseType = false;
    // Once we find that the category/section has the response type, return true to break out of the loop
    return section?.categories?.some((category) => {
        return category?.prompts?.some((prompt) => {
            if (prompt.responseType === responseType) hasResponseType = true;
            return hasResponseType; // if false, keep looking. if true, break out of loop and return early
        });
    });
};

export const generateAssessmentPromptUrl = (assessmentId: string, snapshotId: string, promptId: string): string => {
    if (!assessmentId || !promptId) {
        throw new Error(`generateAssessmentPromptUrl() - invalid assessmentId(${assessmentId}) or promptId(${promptId})`);
    }
    const params = new URLSearchParams();
    params.append('promptId', promptId);
    const newUrl = snapshotId
        ? `/assessments/${assessmentId}/snapshots/${snapshotId}?${params.toString()}`
        : `/assessments/${assessmentId}?${params.toString()}`;
    return newUrl;
};

/**
 * Convert promptId search param: `?promptId=business-costManagement-2` to search param: `?promptId=business-costManagement-0`
 * This is so that even on later prompts in a category, the current category remains highlighted in the side nav
 * (above example is purely hypothetical. promptIds are actually UUIDs)
 * @param searchParam the search params with the prompt ID
 * @returns the search params with the first prompt ID in the category
 */
export const convertSearchParamPromptIdToFirstPromptIdInCategory = (searchParam: string): string => {
    if (!searchParam.startsWith('?promptId=')) {
        // return original url if it is not a promptUrl (e.g. ?promptId=...)
        return searchParam;
    }

    const searchParams = new URLSearchParams(searchParam);
    const promptId = searchParams.get('promptId');
    const firstPromptIdInCategory = getFirstPromptIdInPromptCategory(promptId);

    searchParams.set('promptId', firstPromptIdInCategory);
    return `?${searchParams.toString()}`;
};

export const generateAssessmentResultsReviewAllUrl = (assessmentId: string): string => {
    if (!assessmentId) {
        throw new Error(`generateAssessmentResultsReviewAllUrl() - invalid assessmentId(${assessmentId}) `);
    }
    const url = `/assessments/${assessmentId}/reviewall`;
    return url;
};

export const generateAssessmentIdPromptId = (assessmentId: string | null, promptId: string | null): string | null => {
    if (!!assessmentId && !!promptId) {
        return `${assessmentId}:${promptId}`;
    }
    return null;
};

export const assessmentIsReadOnly = (myUserId: string, assessment: AssessmentViewModel | null, assessmentId: string): boolean => {
    const isSnapshot = assessment?.id !== assessmentId;
    let hasUpdatePermission = false;
    if (assessment && assessment.status !== AssessmentStatus.COMPLETED) {
        const assessmentPermissionManager = new AssessmentPermissionManager(myUserId, assessment.assessmentPermissions);
        hasUpdatePermission = assessmentPermissionManager.hasUpdatePemission;
    }
    return isSnapshot || !hasUpdatePermission;
};

export const reportIsReadOnly = (myUserId: string, assessment: AssessmentViewModel | null, assessmentId: string): boolean => {
    if (['CSM', 'CMA'].includes(assessment?.type)) {
        return cmaReportIsReadOnly(myUserId, assessment, assessmentId);
    }
    return assessmentIsReadOnly(myUserId, assessment, assessmentId);
};

const cmaReportIsReadOnly = (myUserId: string, assessment: AssessmentViewModel | null, assessmentId: string) => {
    const isSnapshot = assessment?.id !== assessmentId;
    let hasUpdatePermission = false;
    if (assessment) {
        const assessmentPermissionManager = new AssessmentPermissionManager(myUserId, assessment.assessmentPermissions);
        hasUpdatePermission = assessmentPermissionManager.hasUpdatePemission;
    }
    return isSnapshot || !hasUpdatePermission;
};

export const getNumberOfResponse = (prompts: AssessmentPromptViewModel[]): number => {
    const numberOfResponses = prompts.reduce((numberOfResponses, prompt) => {
        if (!isPromptConsideredOptional(prompt.id)) {
            if (consideredResponded(prompt.responseType, prompt.response)) {
                numberOfResponses++;
            }
        }
        return numberOfResponses;
    }, 0);
    return numberOfResponses;
};

export const generateAssessmentSnapshotReviewAllUrl = (assessmentId: string, snapshotId: string): string => {
    if (!assessmentId || !snapshotId) {
        throw new Error(`generateAssessmentSnapshotReviewAllUrl() - invalid assessmentId(${snapshotId}) or promptId(${snapshotId})`);
    }
    const newUrl = `/assessments/${assessmentId}/snapshots/${snapshotId}/reviewall`;
    return newUrl;
};

export const assessmentViewModelFromAssessmentSnapshot = (assessment: AssessmentViewModel, snapshot: AssessmentSnapshot): AssessmentViewModel => {
    const assessmentViewModel: AssessmentViewModel = {
        id: snapshot.id,
        description: snapshot.description,
        targetSegmentId: assessment.targetSegmentId,
        createdAt: snapshot.createdAt,
        createdBy: snapshot.createdBy,
        accountCustomerName: assessment.accountCustomerName,
        status: assessment.status,
        type: assessment.type,
        version: assessment.version,
        deliveredBy: assessment.deliveredBy,
        customerAccountID: assessment.customerAccountID,
        updatedAt: assessment.updatedAt,
        lastUpdated: assessment.lastUpdated,
        workshopDate: assessment.workshopDate,
        readoutDate: assessment.readoutDate,
        assessmentPermissions: assessment.assessmentPermissions,
        isDemoTest: assessment.isDemoTest,
        template: snapshot.template,
        percentComplete: null,
        isfavorite: null,
        mapProgramEngagementId: assessment.mapProgramEngagementId,
        opportunity: assessment.opportunity,
        internalContact: assessment.internalContact,
        snapshots: null,
        optOutSolutionsRecommendation: assessment.optOutSolutionsRecommendation,
        assessmentScores: assessment.assessmentScores,
        metadata: [],
    };
    return assessmentViewModel;
};

export const getResponseComments = (response: AssessmentResponse, responseType: AssessmentResponseTypes): string => {
    switch (responseType) {
        case AssessmentResponseTypes.TEXT:
            return (response as AssessmentResponseText)?.stringValue || '';
        default:
            return (response as AssessmentResponseRating)?.comments || '';
    }
};

const getResponseSingleSelectLabelFromId = (
    selectionResponse: AssessmentResponseSingleSelection,
    prompt: AssessmentPrompt | AssessmentPromptViewModel
): string => {
    return (
        prompt?.responseSelections?.selections?.find((selection) => selection.selectionId === selectionResponse?.singleSelectValue)?.selectionLabel ||
        ''
    );
};

const getResponseMultiSelectLabelFromId = (
    selectionResponse: AssessmentResponseMultiSelection,
    prompt: AssessmentPrompt | AssessmentPromptViewModel
): string => {
    return (
        prompt?.responseSelections?.selections
            ?.filter((selection) => selectionResponse?.multiSelectValues?.includes(selection.selectionId))
            .map((selection) => selection.selectionLabel)
            .join(', ') || ''
    );
};

/**
 * Retrieves a localized, ready-for-display value from the provided response
 * @param prompt
 * @param appLabels
 * @param options
 *   - `includeRatingGuide` - if true, return the rating guide instead of the rating. E.g. `5 - <rating guide>`
 *   - `includeTextComment` - if true, return the text comment instead of an empty string
 */
export const getResponseValue = (
    prompt: AssessmentPrompt | AssessmentPromptViewModel,
    appLabels: AppLabels,
    options?: {
        includeRatingGuide?: boolean;
        includeTextComment?: boolean;
    }
): string | number => {
    if (!prompt) {
        return '';
    }
    const { response, responseType } = prompt;
    if (response && responseType) {
        switch (responseType) {
            case AssessmentResponseTypes.RATING: {
                const ratingScore = getScoreLabel({ score: (response as AssessmentResponseRating)?.intValue });
                // Include rating guide if specified
                if (options?.includeRatingGuide) {
                    const ratingGuide = getRatingGuide(prompt);
                    // Some rating guides have the rating (5) at the beginning, others don't. Make sure to include the score
                    return ratingGuide.trim().startsWith(ratingScore.toString()) ? ratingGuide : `${ratingScore} - ${ratingGuide}`;
                } else {
                    return ratingScore;
                }
            }
            case AssessmentResponseTypes.YES_NO:
                return getResponseYesNoLabelLocalized(response as AssessmentResponseBoolean, appLabels);
            case AssessmentResponseTypes.NUMBER:
                return (response as AssessmentResponseNumber)?.intValue || -1;
            case AssessmentResponseTypes.TEXT:
                //  Normally, text responses are included in getResponseComments instead
                return options?.includeTextComment ? (response as AssessmentResponseText)?.stringValue : '';
            case AssessmentResponseTypes.DATE_VALUE:
                return (response as AssessmentResponseDate)?.dateValue || '';
            case AssessmentResponseTypes.SINGLE_SELECTION:
                return getResponseSingleSelectLabelFromId(response as AssessmentResponseSingleSelection, prompt);
            case AssessmentResponseTypes.MULTI_SELECTION:
                return getResponseMultiSelectLabelFromId(response as AssessmentResponseMultiSelection, prompt);
            default: {
                rumClient.recordError(`getResponseValue() - unknown response type when trying to get question results: ${responseType}`);
                return '';
            }
        }
    }
    return '';
};

/**
 * Gets rating description from the given prompt
 * @param prompt
 * @param assessmentDefaults
 */
export const getRatingGuide = (
    prompt: AssessmentPrompt | AssessmentPromptViewModel,
    assessmentDefaults?: AssessmentTemplateDefaultsAnswers
): string => {
    if (prompt?.responseType === AssessmentResponseTypes.RATING) {
        const response = prompt?.response as AssessmentResponseRating;
        const rating = response?.intValue;

        let key: string;
        // AssessmentPrompt has the rating guide in `rating1`. AssessmentPromptViewModel has it in `ratingGuide`
        // Check every possible rating, since some assessment types don't have rating guides for certain numbers
        for (let ratingNum = 1; ratingNum <= Constants.A2S_MAX_POSSIBLE_RATING; ++ratingNum) {
            if (`rating${ratingNum}` in prompt) {
                key = `rating${rating}`;
                break;
            } else if (`ratingGuide${ratingNum}` in prompt) {
                key = `ratingGuide${rating}`;
                break;
            }
        }
        // Make sure rating guide is in the prompt, and make sure the guide is truthy
        if (key in prompt && prompt[key as keyof typeof prompt]) {
            return prompt[key as keyof typeof prompt] as string;
        } else if (assessmentDefaults && key in assessmentDefaults) {
            return assessmentDefaults[key as keyof typeof assessmentDefaults] as string;
        }
    }
    return '';
};

export interface QuestionResults {
    sectionLabel: string | null;
    categoryName: string | null;
    workstreamName: string | null;
    activityName: string | null;
    promptId: string | null;
    questionNumber: number;
    questionText: string;
    responseType: AssessmentResponseTypes;
    response: string | number;
    ratingGuide: string;
    allRatingGuides: string[];
    comments: string;
}

export const getQuestionResults = (assessment: AssessmentViewModel, appLabels: AppLabels): QuestionResults[] => {
    const MAX_POSSIBLE_RATING = Constants.A2S_MAX_POSSIBLE_RATING;
    const results: QuestionResults[] = [];
    const assessmentMetadataProvider = getAssessmentMetadataProvider(assessment);
    for (const section of assessment?.template?.sections || []) {
        for (const category of section?.categories || []) {
            for (const prompt of category?.prompts || []) {
                const responseType = prompt?.responseType;
                const questionNumber = assessmentMetadataProvider.getPromptNumber(prompt?.id);
                const questionText = prompt?.label?.text || '';
                const comments = getResponseComments(prompt?.response, responseType);
                const ratingOrResponse = getResponseValue(prompt, appLabels);
                const ratingGuide = getRatingGuide(prompt, assessment.template.defaults?.questionnaireAnswers);

                const allRatingGuides = [];
                if (prompt) {
                    for (let ratingNum = 1; ratingNum <= MAX_POSSIBLE_RATING; ratingNum++) {
                        allRatingGuides.push(prompt[`rating${ratingNum}`]);
                    }
                }

                results.push({
                    sectionLabel: section?.label,
                    categoryName: category?.name,
                    workstreamName: null,
                    activityName: null,
                    promptId: prompt?.id,
                    questionNumber,
                    questionText,
                    responseType,
                    response: ratingOrResponse,
                    ratingGuide,
                    allRatingGuides,
                    comments,
                });
            }
        }
    }

    for (const workstream of assessment?.template?.workstreams || []) {
        for (const activity of workstream?.activities || []) {
            for (const prompt of activity?.prompts || []) {
                const responseType = prompt?.responseType;
                const questionNumber = assessmentMetadataProvider.getPromptNumber(prompt?.id);
                const questionText = prompt?.label?.text || '';
                const comments = getResponseComments(prompt?.response, responseType);
                const ratingOrResponse = getResponseValue(prompt, appLabels);
                const ratingGuide = getRatingGuide(prompt, assessment.template.defaults?.questionnaireAnswers);

                const allRatingGuides = [];
                if (prompt) {
                    for (let ratingNum = 1; ratingNum <= MAX_POSSIBLE_RATING; ratingNum++) {
                        allRatingGuides.push(prompt[`rating${ratingNum}`]);
                    }
                }

                results.push({
                    sectionLabel: null,
                    categoryName: null,
                    workstreamName: workstream?.name,
                    activityName: activity?.name,
                    promptId: prompt?.id,
                    questionNumber,
                    questionText,
                    responseType,
                    response: ratingOrResponse,
                    ratingGuide,
                    allRatingGuides,
                    comments,
                });
            }
        }
    }

    return results;
};

/**
 * Provided a prompt id, `findPrompt` returns the prompt from the assessment
 * @param assessment
 * @param promptId
 * @returns the corresponding prompt if found, or null if not found
 */
export const findPrompt = (assessment: AssessmentViewModel, promptId: PromptId): AssessmentPrompt | null => {
    let foundPrompt = null;
    // Once we find the prompt, the iteration will return false, breaking out of the loop
    assessment?.template?.sections?.every((section) => {
        section?.categories?.every((category) => {
            const prompt = category?.prompts?.find((prompt) => prompt?.id === promptId);
            if (prompt) {
                foundPrompt = prompt;
            }
            return !foundPrompt;
        });
        return !foundPrompt;
    });
    return foundPrompt;
};

/**
 * Validates assessment attribute state taking into consideration some assessment attributes maybe excluded by assessment type
 * @param assessmentState Assessment state to be validated
 * @param excludeOptionalMetadataKeys List of assessment attributes to be excluded from validation
 * @param requiredMetadataKeys List of assessment attributes that need to be filled out
 * @returns List of validation errors for the required attribute
 */
export const validateAssessmentAndMetadataKeys = async (
    assessmentState: AssessmentCreateState,
    excludeOptionalMetadataKeys: A2sOptionalAssessmentMetadataKey[],
    requiredMetadataKeys?: A2sOptionalAssessmentMetadataKey[],
    isPartnerUser?: boolean
): Promise<ValidationError[]> => {
    const assessment: Assessment = Object.assign(new Assessment(), assessmentState);
    const validationErrors = (await validate(assessment)).filter((error) => {
        const invalidMetadataKey = error?.property;
        // Exclude opportunity validation for partner users
        if (isPartnerUser && invalidMetadataKey === Constants.OPPORTUNITY) {
            return false;
        } else if (excludeOptionalMetadataKeys.some((k: string) => k === invalidMetadataKey)) {
            // attribute is excluded, remove error from list
            return false;
        }

        return true;
    });
    // Make sure all required metadata keys are filled out too
    validationErrors.push(...validateRequiredMetadataKeysInAssessment(assessmentState, requiredMetadataKeys));

    return validationErrors;
};

/**
 * Ensures an assessment has all required metadata keys set (e.g. some assessments require opportunity to be set)
 * @param assessmentState the assessment information that the user filled out
 * @param requiredMetadataKeys the metadata keys that are required to be set
 * @returns a list of validation errors, indicating if there is any data missing
 */
export const validateRequiredMetadataKeysInAssessment = (
    assessmentState: AssessmentCreateState,
    requiredMetadataKeys: A2sOptionalAssessmentMetadataKey[]
): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!Array.isArray(requiredMetadataKeys)) {
        rumClient.recordError('validateRequiredMetadataKeysInAssessment() - requiredMetadataKeys is not an array');
        return errors;
    }

    requiredMetadataKeys.forEach((requiredKey) => {
        // Check opportunity
        if (requiredKey === A2sOptionalAssessmentMetadataKey.opportunityId) {
            if (!assessmentState.opportunity) {
                errors.push({
                    property: 'opportunity',
                    constraints: {
                        isNotEmpty: 'Opportunity cannot be empty',
                    },
                });
            }
        } else if (requiredKey === A2sOptionalAssessmentMetadataKey.mapProgramEngagementId) {
            // Check engagement
            if (!assessmentState.mapProgramEngagementId) {
                errors.push({
                    property: 'mapProgramEngagementId',
                    constraints: {
                        isNotEmpty: 'mapProgramEngagementId cannot be empty',
                    },
                });
            }
        } else {
            rumClient.recordError(`validateRequiredMetadataKeysInAssessment() - unknown required key: ${requiredKey}`);
        }
    });

    return errors;
};

/**
 * Get the excluded and required metadata keys in an assessment template
 * @param template the assessment template, either in A2S or TM forms
 * @param isPartnerUser whether the user is a partner
 * @returns memoized excludedMetadataKeys and requiredMetadataKeys
 */
export const getExcludedAndRequiredMetadataKeys = (
    template: AssessmentTemplate | TmAssessmentTemplate | undefined,
    isPartnerUser: boolean
): {
    excludedMetadataKeys: A2sOptionalAssessmentMetadataKey[];
    requiredMetadataKeys: A2sOptionalAssessmentMetadataKey[];
} => {
    if (!template) {
        return { excludedMetadataKeys: [], requiredMetadataKeys: [] };
    }

    let excludedMetadataKeys: A2sOptionalAssessmentMetadataKey[];
    let requiredMetadataKeys: A2sOptionalAssessmentMetadataKey[];

    if ('metadataConfig' in template) {
        excludedMetadataKeys = template.metadataConfig?.excludeOptionalMetadataKeys ?? ([] as A2sOptionalAssessmentMetadataKey[]);
        requiredMetadataKeys = template.metadataConfig?.requiredMetadataKeys ?? ([] as A2sOptionalAssessmentMetadataKey[]);
    } else {
        // TM and A2S enums overlap here
        excludedMetadataKeys = (template.config?.assessmentMetadataConfig?.excludedMetadataPrompts ||
            []) as unknown as A2sOptionalAssessmentMetadataKey[];
        requiredMetadataKeys = (template.config?.assessmentMetadataConfig?.requiredMetadataPrompts ||
            []) as unknown as A2sOptionalAssessmentMetadataKey[];
    }

    // Don't require partners to fill out opportunity/engagement. They don't have access to AWS SFDC
    if (isPartnerUser) {
        requiredMetadataKeys = requiredMetadataKeys.filter(
            (key) => key !== A2sOptionalAssessmentMetadataKey.opportunityId && key !== A2sOptionalAssessmentMetadataKey.mapProgramEngagementId
        );
    }

    return { excludedMetadataKeys, requiredMetadataKeys };
};

/**
 * Extracts the list of drivers from a driver string
 * @param driverStr the string with all drivers for that category
 * @returns a list of drivers parsed from the string
 */
export const parseDrivers = (driverStr: string): string[] => {
    if (!driverStr) {
        return [];
    }
    return driverStr
        .split(',')
        .map((driver) => driver.trim())
        .filter((driver) => !!driver.length);
};

/**
 * Returns a sorted list of all unique drivers in an assessment
 * @param assessment the assessment
 * @returns a sorted list of all drivers in the assessment
 */
export const getAllDriversInAssessment = (assessment: AssessmentViewModel): string[] => {
    // Get a set of all drivers in the assessment
    const driversSet: Set<string> = new Set<string>();
    assessment?.template.sections.forEach((section) => {
        section.categories.forEach(({ driver }) => {
            const driverText = driver?.text;
            const parsedDrivers = parseDrivers(driverText);
            parsedDrivers.forEach((parsedDriver) => {
                driversSet.add(parsedDriver);
            });
        });
    });

    // Convert the set to a list and sort it
    const driversList = Array.from(driversSet);
    return driversList.sort();
};

/**
 * Gets the maximum rating in an assessment. Returns 0 if there are no ratings
 * @param assessment the assessment
 * @returns the maximum possible rating of any question
 */
export const getMaxRatingInAssessment = (assessment: AssessmentViewModel): number => {
    const MAX_POSSIBLE_RATING = Constants.A2S_MAX_POSSIBLE_RATING;
    let maxRatingInAssessment = 0;
    // Once we find that the assesment has the max possible rating, the iteration will return false, breaking out of the loop
    assessment?.template.sections.every((section) => {
        section.categories.every((category) => {
            category.prompts.every((prompt) => {
                // Check if this prompt has any rating guides greater than the current max
                for (let ratingNum = MAX_POSSIBLE_RATING; ratingNum > maxRatingInAssessment; ratingNum--) {
                    if (prompt[`rating${ratingNum}`]) {
                        maxRatingInAssessment = ratingNum;
                    }
                }
                return maxRatingInAssessment !== MAX_POSSIBLE_RATING;
            });
            return maxRatingInAssessment !== MAX_POSSIBLE_RATING;
        });
        return maxRatingInAssessment !== MAX_POSSIBLE_RATING;
    });

    return maxRatingInAssessment;
};

/**
 * Extracts a display-ready prompt string from a prompt object
 * @param prompt the prompt object
 * @param promptConfig the template configuration for prompts
 * @returns a string representing the prompt, that can be displayed to the user
 */
export const promptToPromptText = (prompt: AssessmentPromptViewModel, promptConfig?: AssessmentTemplateDefaultsAnswers): string => {
    if (!prompt) {
        return '';
    }

    const questionLabel = promptConfig?.questionLabel ?? Constants.ASSESSMENT_DEFAULT_QUESTION_LABEL;
    return prompt.label?.text ? `${questionLabel} ${prompt.index}: ${prompt.label.text}` : '';
};
/**
 * Some account IDs have `AWS:` prepended. This function extracts the SFDC ID from the account ID, whether it has the prefix or not
 * @param accountId the account ID
 * @returns the SFDC ID that can be sent to CAS
 */
export const getSfdcIdFromAccountId = (accountId: string): string => {
    return accountId.substring(accountId.indexOf(':') + 1);
};
