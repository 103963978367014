import { AssessmentTemplate } from '@amzn/aws-assessment-template-management-service-typescript-client';
import { AssessmentStatus } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { AuthContextInterface, FlashContextInterface, FlashType, withAuthContext, withFlashContext } from '@amzn/awscat-react-components';
import { Link, SpaceBetween, Wizard } from '@amzn/awsui-components-react';
import { useLazyQuery } from '@apollo/client';
import { ValidationError } from 'class-validator';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { AssessmentCreateState, defaultAssessmentCreateState } from './AssessmentCreateState';
import AssessmentCustomerPanel from './AssessmentCustomerPanel';
import AssessmentCustomerProfilePanel from './AssessmentCustomerProfilePanel';
import AssessmentDetailsPanelForTmTemplate from './AssessmentDetailsPanelForTmTemplate';
import AssessmentTypePanel from './AssessmentTypePanel';
import Paths from '../../../Paths';
import a2sClient from '../../../api/a2s/A2SClient';
import templateManagementClient from '../../../api/templateManagement/TemplateManagementClient';
import { LIST_TEMPLATES_QUERY } from '../../../api/templateManagement/TemplateManagementQueries';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import { logger } from '../../../common/Logger';
import { isAWSUser, isApnUser, isoDateToLocaleDate } from '../../../common/Utils';
import rumClient from '../../../common/monitoring/RumClient';
import { RequestStatus, defaultRequestStatus } from '../../common/RequestStatusFlashbar';
import { ShouldNavigateFn, useWizard } from '../../common/Wizard';
import { clearAppHelpPanel, openAppHelpPanel } from '../../common/help-panel/AppHelpPanelSlice';
import { CustomerFilterContextInterface, withCustomerFilterContext } from '../../customers/CustomerFilterContext';
import { CustomerViewModel } from '../../customers/CustomerViewModel';
import { loadCustomersAsync } from '../../customers/Customers';
import { loadCustomersSuccess } from '../../customers/CustomersSlice';
import { useAppDispatch } from '../../redux/hooks';
import { getExcludedAndRequiredMetadataKeys, validateAssessmentAndMetadataKeys } from '../Utils';

type StepComponentProps = {
    assessmentTemplates: AssessmentTemplate[];
    createState: AssessmentCreateState;
    onChange: (data: Partial<AssessmentCreateState>) => void;
    validationErrors: ValidationError[];
    tableItems: CustomerViewModel[];
    setTableItems: any;
    searchKeyword: string;
    setSearchKeyword: any;
    requestStatus: RequestStatus;
    setRequestStatus: any;
};

interface Step {
    title: string;
    description: string;
    StepContent: React.FunctionComponent<StepComponentProps>;
}

/**
 * Submits the "Create assessment" form
 * @param state the assessment create state, with data entered by the user
 * @returns the ID of the newly created assessment
 */
const submitAssessment = async (state: AssessmentCreateState): Promise<string> => {
    const { type, version } = state;
    if (state.account?.id && type && version) {
        const metadata = state.metadata.filter((m) => !!m.value);
        const { id, accountName, industry, region, territory, geo, segment } = state.account;
        const result = await a2sClient.createAssessment({
            customerAccountId: id,
            customerAccountInfo: {
                accountId: id,
                accountName,
                industry,
                region,
                territory,
                geo,
                segment,
            },
            description: state.description,
            targetSegmentId: state.customerProfile?.targetSegmentId,
            status: AssessmentStatus.IN_PROGRESS,
            type,
            version,
            mapProgramEngagementId: state.mapProgramEngagementId || undefined, // exclude falsy values (e.g. empty string)
            opportunity: state.opportunity || undefined,
            internalContact: state.internalContact || undefined, // exclude falsy values (e.g. empty string)
            readoutDate: state.readoutDate ? isoDateToLocaleDate(state.readoutDate) : undefined,
            workshopDate: state.workshopDate ? isoDateToLocaleDate(state.workshopDate) : undefined,
            deliveredBy: state.deliveredBy?.value,
            isDemoTest: state.isDemoTest,
            optOutSolutionsRecommendation: state.optOutSolutionsRecommendation,
            customerCloudMaturity: state.customerProfile?.customerCloudMaturity,
            customerPlanAndAlignment: state.customerProfile?.customerPlanAndAlignmentExists,
            metadata,
        });

        if (result.errors) {
            logger.error(`createAssessment() ${result.errors}`);
            throw new Error('Error submitting assessment');
        }

        return result.data.createAssessment.id;
    }

    return '';
};

const Customer: FunctionComponent<StepComponentProps> = ({
    createState,
    onChange,
    tableItems,
    setTableItems,
    searchKeyword,
    setSearchKeyword,
    requestStatus,
    setRequestStatus,
}) => {
    return (
        <div>
            <AssessmentCustomerPanel
                selectedAccount={createState.account}
                onChange={onChange}
                tableItems={tableItems}
                setTableItems={setTableItems}
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
                requestStatus={requestStatus}
                setRequestStatus={setRequestStatus}
            />
        </div>
    );
};

const CustomerProfile: FunctionComponent<StepComponentProps> = ({ createState, onChange, validationErrors }) => {
    return (
        <div>
            <SpaceBetween size='s'>
                <AssessmentCustomerProfilePanel
                    validationErrors={validationErrors}
                    state={createState}
                    onChange={onChange}
                ></AssessmentCustomerProfilePanel>
            </SpaceBetween>
        </div>
    );
};

const AssessmentTypes: FunctionComponent<StepComponentProps> = ({ assessmentTemplates, createState, onChange, validationErrors }) => {
    return (
        <div>
            <SpaceBetween size='s'>
                <AssessmentTypePanel
                    validationErrors={validationErrors}
                    assessmentTemplates={assessmentTemplates}
                    selectedAssessmentType={createState.type}
                    onChange={onChange}
                ></AssessmentTypePanel>
            </SpaceBetween>
        </div>
    );
};

const Details: FunctionComponent<StepComponentProps> = ({ assessmentTemplates, createState, onChange, validationErrors }) => {
    return (
        <div>
            <SpaceBetween size='s'>
                <AssessmentDetailsPanelForTmTemplate
                    validationErrors={validationErrors}
                    assessmentTemplates={assessmentTemplates}
                    state={createState}
                    onChange={onChange}
                ></AssessmentDetailsPanelForTmTemplate>
            </SpaceBetween>
        </div>
    );
};

type AssessmentCreateProp = FlashContextInterface & AppLabelsContextInterface & CustomerFilterContextInterface & AuthContextInterface;

const AssessmentCreate: FunctionComponent<AssessmentCreateProp> = ({ auth, appLabels }): JSX.Element | null => {
    const history = useHistory();
    const [createState, setCreateState] = useState<AssessmentCreateState>({ ...defaultAssessmentCreateState, status: AssessmentStatus.IN_PROGRESS });
    const [assessmentTemplates, setAssessmentTemplates] = useState<AssessmentTemplate[]>([]);
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
    const [tableItems, setTableItems] = useState<CustomerViewModel[] | null>(null);
    const [searchKeyword, setSearchKeyword] = useState<string | null>(null);
    const [requestStatus, setRequestStatus] = useState<RequestStatus>(defaultRequestStatus);
    const isAwsUser = isAWSUser(auth?.getUserInfo());
    const isPartnerUser = isApnUser(auth?.getUserInfo().userId);
    const [errorText, setErrorText] = useState<string>('');

    /* Get the available assessment types from Template Management */
    const [listAvailableAssessmentTypes] = useLazyQuery(LIST_TEMPLATES_QUERY, {
        client: templateManagementClient,
        onError: (error) => {
            setRequestStatus({
                loading: false,
                messageType: FlashType.error,
                messageHeader: appLabels.assessment.create.error_retrieving_all_templates,
                messageContent: error.message,
            });
            rumClient.recordError(error);
        },
        onCompleted: (data) => {
            const templates: AssessmentTemplate[] = data.listTemplates;
            setAssessmentTemplates(templates);
        },
    });

    const { excludedMetadataKeys, requiredMetadataKeys } = getExcludedAndRequiredMetadataKeys(
        assessmentTemplates?.find((t) => t.type === createState.type),
        isPartnerUser
    );

    const closeTools = () => {
        return;
    }; // For future use with info bar
    const dispatch = useAppDispatch();
    const shouldNavigate: ShouldNavigateFn = (event) => {
        // Forbid navigating away from customer account page, unless an account is selected
        if (event.detail.requestedStepIndex === 1 && !createState.account) {
            setErrorText(appLabels.assessment.create.select_customer.no_account_selected);
            return false;
        }
        // Reset any other error text before navigating. This applies to the "previous" button
        setErrorText('');
        return true;
    };
    const { activeStepIndex, onNavigate, onCancel } = useWizard(closeTools, shouldNavigate);

    const i18nStrings = {
        stepNumberLabel: (stepNumber: number | string) => `${appLabels.common.step} ${stepNumber}`,
        collapsedStepsLabel: (stepNumber: number | string, stepsCount: number | string) => {
            return `${appLabels.common.step} ${stepNumber} / ${stepsCount}`;
        },
        cancelButton: appLabels.user_actions.cancel,
        previousButton: appLabels.user_actions.previous,
        nextButton: appLabels.user_actions.next,
        submitButton: appLabels.user_actions.submit,
        optional: appLabels.common.optional,
    };

    const steps: Step[] = [
        {
            title: appLabels.assessment.create.select_customer.title,
            description: isAwsUser
                ? appLabels.assessment.create.select_customer.description
                : appLabels.assessment.create.select_customer.description_partner,
            StepContent: Customer,
        },
        {
            title: appLabels.assessment.create.customer_profile.title,
            description: '',
            StepContent: CustomerProfile,
        },
        {
            title: appLabels.assessment.create.select_assessment_type.title,
            description: '',
            StepContent: AssessmentTypes,
        },
        {
            title: appLabels.assessment.create.enter_assessment_details,
            description: '',
            StepContent: Details,
        },
    ];

    const onSubmit = useCallback(async () => {
        try {
            const validationErrors = await validateAssessmentAndMetadataKeys(createState, excludedMetadataKeys, requiredMetadataKeys, isPartnerUser);
            setValidationErrors(validationErrors);
            if (!(validationErrors && validationErrors.length)) {
                const idOfNewAssessment = await submitAssessment(createState);
                // Navigate to the newly created assessment
                history.push(`${Paths.BASE_ASSESSMENTS_PATH}/${idOfNewAssessment}`);
            }
        } catch (error) {
            rumClient.recordError(error);
            setRequestStatus({
                loading: false,
                messageType: FlashType.error,
                messageHeader: appLabels.common.error,
                messageContent: appLabels.assessment.create.error_creating_assessment,
            });
        }
    }, [
        appLabels.common.error,
        appLabels.assessment.create.error_creating_assessment,
        createState,
        excludedMetadataKeys,
        requiredMetadataKeys,
        history,
        isPartnerUser,
    ]);

    const onChange = (subStateValue: Partial<AssessmentCreateState>) => {
        const newState = {
            ...createState,
            ...subStateValue,
        };
        setCreateState(newState);
    };

    const loadCustomersForExistingAssessments = useCallback(async (): Promise<void> => {
        try {
            const customers = await loadCustomersAsync();
            dispatch(loadCustomersSuccess(customers));
            logger.info(`loadCustomersForExistingAssessments: num customers retrieved: ${customers.length}`);
        } catch (err) {
            rumClient.recordError(err);
            logger.error(`loadCustomersFoEexistingAssessments error: ${err}`);
        }
    }, [dispatch]);

    useEffect(() => {
        const cleanup = () => {
            // Component unmounted, restore help panel to default
            dispatch(clearAppHelpPanel());
        };

        loadCustomersForExistingAssessments();
        listAvailableAssessmentTypes();
        return cleanup;
        // Do this only on component load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Remove any error text once the relevant field is filled out
        if (errorText === appLabels.assessment.create.select_customer.no_account_selected && createState.account) {
            setErrorText('');
        }
    }, [errorText, createState, appLabels.assessment.create.select_customer.no_account_selected]);

    const wizardSteps = steps.map(({ title, description, StepContent }) => ({
        title,
        description,
        info: (
            <Link
                variant='info'
                id='assessment-create-info-link'
                onFollow={() => {
                    dispatch(openAppHelpPanel());
                }}
            >
                {appLabels.common.info}
            </Link>
        ),
        content: (
            <StepContent
                validationErrors={validationErrors}
                assessmentTemplates={assessmentTemplates}
                createState={createState}
                onChange={onChange}
                tableItems={tableItems}
                setTableItems={setTableItems}
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
                requestStatus={requestStatus}
                setRequestStatus={setRequestStatus}
            />
        ),
        errorText,
    }));

    return (
        <div className='awscat-applayout-content awscat-assessment-wrapper'>
            <Wizard
                steps={wizardSteps}
                activeStepIndex={activeStepIndex}
                i18nStrings={i18nStrings}
                onNavigate={onNavigate}
                onCancel={onCancel}
                onSubmit={onSubmit}
                data-testid='create-assessment-wizard'
            />
        </div>
    );
};

export default withAuthContext(withAppLabelsContext(withCustomerFilterContext(withFlashContext(AssessmentCreate))));
